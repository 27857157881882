.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

app-tips-day-widget {
  .md-card {
      max-height: 270px;
  }
}

.pp-logo {
  height: 60px;
  width: auto;
}

.no-image {
  img {
      display: none;
  }
}

.feed-banner {
  img {
      display: none;
  }
  img:first-child {
      // max-width: 100%;
      // height: auto;
      // display: block !important;
      max-width: 200px;
      display: block !important;
      max-height: 100px;
      margin: auto;
  }
}

app-help {
  app-faq-section-widget {
      h4 {
          // color: black !important;
      }
  }
}

.cursor-loader {
  cursor: wait;
  * {
      cursor: wait !important;
  }
}

.c-pointer,
.cursor-link {
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  .cdk-global-overlay-wrapper {
      top: 60px;
  }
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 12px solid white;
}

.marg-vert {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.icon-select-panel.mat-select-panel {
  display: flex;
  flex-wrap: wrap;
  width: 280px;
  max-width: 280px;
  max-height: 260px;
  margin-top: 50px;
  margin-left: -32px;

  .mat-option {
    width: 25%;
    max-width: 25%;
    min-width: 25%;
    padding: 8px !important;
    span.mat-option-text {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
    }
  }
}

.mat-table {
  background-color: white !important;
}
