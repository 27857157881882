
// Cards works similar to panels
.md-card {
  position: relative;
  background-color: #fff;
  margin-bottom: 20px;
  @include material-shadow();

  .md-card-heading {
    padding: 20px 22px;
    > .md-card-title {
      margin: 0;
      font-size: 18px;
    }
    > .md-card-icon {
      float: right;
      color: rgba(255,255,255,.87);
      font-size: 20px;
    }
  }
  .md-card-body {
    position: relative;
    padding: 20px 22px
  }
  .md-card-item {
    position: relative;
    min-height: 120px;
    > p {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0,0,0,0.15);
      margin: 0;
    }
  }
  .md-card-offset {
    position: relative;
    z-index: 1;
    > * {
      position: absolute;
      top: 0;
      right: 15px;
      transform: translate(0, -50%);
    }
  }

  .md-card-toolbar {
    position: relative;
    width: 100%;
    min-height: 64px;
    font-size: 18px;
    line-height: 64px;
    padding-left: 22px;
    z-index: 2;
  }
  .md-card-subheader {
    padding: 16px 0 16px 16px;
    line-height: .75em;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .01em;
    color: rgba(0,0,0,0.54);
    // Adjustmen for list next to subheader
    + .mda-list > .mda-list-item:first-child {
      > .mda-list-item-text {
        padding-top: 16px;
      }
      > .mda-list-item-img,
      > .mda-list-item-icon,
      > .mda-list-item-initial {
        margin-top: 10px;
      }
    }
  }

  .md-card-footer {
    padding: 10px 22px;
  }


  .md-card-divider {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(0,0,0,0.12);
  }

  .ui-datepicker {
    width: 100%;
    @include box-shadow(0 0 0 #000);
    margin: 0;
    > table {
      width: 100%;
    }
  }


  &.md-card-map {

    min-height: 280px;
    .md-card-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border: 0;
      background-color: transparent;
    }
  }


}